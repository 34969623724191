/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { Box, Grid } from '@mui/material';
import { groupBy, first } from 'lodash';
import CreateOrEditGroup from './HandleGroupSlideout';
import API from 'utils/axios';
import { searchToObject } from 'utils/object.util';
import { REPORT_RUNS_STATUS } from './constants';
import { makeStyles } from '@mui/styles/';
import { DeleteReportGroupModal } from './DeleteGroupModal';
import { ConfirmationDialog } from './ConfirmationDialog';
import { ReportFooter } from './ReportFooter';
import { ReportTitleHeader } from './ReportTitleHeader';
import { ReportSearchContainer } from './ReportSearchContainer';
import { ReportTabsList } from './ReportTabsList';
import { GroupDetails } from './GroupDetails';
import { ReportRunsDetails } from './ReportRunsDetails';
import { HANDLE_GROUP_SLIDEOUT } from 'constants/reports';
import EditReportDetailsSlideout from './EditReportDetailsSlideout';
import { settingsUpdate, updateNetworkView } from 'redux/actions';
import { LEFTNAV_OPTION } from 'constants/settings';
import { REPORT_STATUS } from 'constants/network';
import CompletedModal from 'components/Modals/CompletedModal';
import AlertsContainer from 'components/NetworkView/Alerts/AlertsContainer';
import { useNetworkManagerHook } from 'utils/hooks';
import { useNotificationHook } from 'utils/hooks/notificationHooks';
import theme from 'theme';

const NetworkManager = () => {
    const { newNotificationFlow } = useFlags();
    const dispatch = useDispatch();
    const [bucket, setBucket] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [tabValue, setTabValue] = useState(REPORT_STATUS.COMPLETED);
    const [filterStatus, setFilterStatus] = useState([
        REPORT_STATUS.COMPLETED,
        REPORT_STATUS.VFA_COMPLETED
    ]);
    const location = useLocation();
    const navigate = useNavigate();
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            height: '100vh'
        }
    }));
    const classes = useStyles();
    const alertsContext = useAlerts();
    const { isCompletedReport } = useSelector((state) => state.NetworkReducer);
    const {
        getNetworkManagerReports,
        buckets,
        setBuckets,
        action,
        setAction,
        reportRun,
        setReportRun,
        allReports,
        setAllReports
    } = useNetworkManagerHook();

    const editBucket = (bucket) => {
        setBucket(bucket);
        setAction(HANDLE_GROUP_SLIDEOUT.EDIT_BUCKET);
    };
    useNotificationHook();

    useEffect(() => {
        getBuckets(true);
    }, []);

    useEffect(() => {
        if (buckets.length === 0) return;
        const search = searchToObject(location?.search || '');
        const allSelectedBuckets = buckets.length;
        const selectedBuckets = buckets.filter((item) => item.checked === true).length;
        if (search.bucketId && allSelectedBuckets === selectedBuckets) {
            const _buckets = buckets.map((bucket) => ({
                ...bucket,
                checked: parseInt(bucket.id) === parseInt(search.bucketId)
            }));

            setBuckets(_buckets);
        }
    }, [buckets.length, location?.search]);

    const getBuckets = async (forceChecked = false) => {
        dispatch(updateNetworkView({ fetchingBuckets: true }));
        let {
            data: { results }
        } = await API.get(`/buckets/`);

        if (forceChecked) {
            const search = searchToObject(location?.search || '');
            results = results.map((bucket) => ({
                ...bucket,
                checked: search.bucketId ? parseInt(bucket.id) === parseInt(search.bucketId) : true
            }));
        } else {
            const _selectedBuckets = buckets.filter(({ checked }) => checked).map(({ id }) => id);
            results = results.map((bucket) => ({
                ...bucket,
                checked: _selectedBuckets.includes(bucket.id)
            }));
        }

        dispatch(updateNetworkView({ fetchingBuckets: false }));
        setBuckets(results);
    };

    const onSidebarClose = (reload = false) => {
        if (reload) getBuckets();
        setAction(null);
    };

    const onBucketChecked = (bucket, checked) => {
        const id = location?.search?.split('=')[1];
        const path = location?.pathname;
        if (id && bucket.id === 'all') navigate(path);
        const _buckets = buckets.map((item) => {
            if (item.id === bucket.id || bucket.id === 'all') item.checked = checked;
            return item;
        });
        setBuckets(_buckets);
    };

    const editReport = (reportRun) => {
        setAction('editReportDetails');
        setReportRun(reportRun);
    };

    const deleteReport = () => setAction('deleteReportDetails');

    const updateSearch = (value) => {
        if (value && !searchText) setTabValue('all');

        const reportRuns = allReports.map((item) => ({
            ...item,
            isChecked: false
        }));
        setSearchText(value);
        setAllReports(reportRuns);
    };

    const onReportChecked = (selectedItem, value) => {
        const { id, configuration, name } = selectedItem;
        const reportRuns = allReports.map((item) => {
            if (
                (item?.id === id && item?.name === name && item?.configuration === configuration) ||
                selectedItem === 'all'
            )
                item.isChecked = value;
            return item;
        });
        setAllReports(reportRuns);
    };

    const updateTabValue = (_e, tabValue) => {
        const filterStatus = REPORT_RUNS_STATUS.find(({ status }) => status === tabValue).filter;
        setTabValue(tabValue);
        setFilterStatus(filterStatus);
    };

    const selectedBuckets = buckets.reduce((store, { checked, id }) => {
        checked && store.push(id);
        return store;
    }, []);
    const isAllBucketsChecked = buckets.length === selectedBuckets.length;

    useEffect(() => {
        dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.REPORT_MANAGER }));
    }, []);

    let filteredReports = allReports;

    if (searchText) {
        const search = searchText.toLowerCase();
        filteredReports = filteredReports.filter(
            ({ name, bucketName }) =>
                (name || '').toLowerCase().includes(search) ||
                (bucketName || '').toLowerCase().includes(search)
        );
    }

    if (!isAllBucketsChecked)
        filteredReports = filteredReports.filter(({ bucket }) => selectedBuckets.includes(bucket));

    const reportRuns = groupBy(filteredReports, 'report_status');

    filteredReports = filteredReports.filter(({ report_status }) =>
        filterStatus.includes(report_status)
    );

    const selectedRecords = filteredReports.filter(({ isChecked }) => isChecked);
    const hasSelectedRows = selectedRecords.length > 0;

    useEffect(() => {
        dispatch(updateNetworkView({ tempOpenHistory: false }));
        dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_MANAGER }));
    }, []);

    useEffect(() => {
        const unsetReports = allReports.map((item) => ({
            ...item,
            isChecked: false
        }));
        setAllReports(unsetReports);
    }, [tabValue]);

    return (
        <div className={clsx(classes.root, 'bucket-list')} tabIndex={''}>
            <Grid
                containe
                sx={{
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.neutral.neutral4,
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral6
                        }
                    }
                }}>
                <Grid item xs={12}>
                    <ReportTitleHeader />
                </Grid>
                <Grid item xs={12}>
                    <ReportSearchContainer searchText={searchText} updateSearch={updateSearch} />
                </Grid>
                <Grid item xs={12} container>
                    {!searchText && (
                        <GroupDetails
                            filteredReports={filteredReports}
                            buckets={buckets}
                            onRowChecked={onBucketChecked}
                            onAction={setAction}
                            editBucket={editBucket}
                            getBuckets={getBuckets}
                        />
                    )}

                    <Grid item xs={searchText ? 12 : 10}>
                        <Box
                            sx={{
                                overflowX: 'auto',
                                width: '-webkit-fill-available'
                            }}>
                            <ReportTabsList
                                reportRuns={reportRuns}
                                value={tabValue}
                                onChange={updateTabValue}
                            />
                            {selectedRecords.length > 0 && (
                                <ReportFooter
                                    key={'report-selection-footer'}
                                    record={first(selectedRecords)}
                                    selectedRecords={selectedRecords}
                                    editReport={editReport}
                                    deleteReport={deleteReport}
                                    onAction={setAction}
                                />
                            )}
                            <ReportRunsDetails
                                tabValue={tabValue}
                                hasSelectedRows={hasSelectedRows}
                                filteredReports={filteredReports}
                                onReportChecked={onReportChecked}
                                editReport={editReport}
                                getReportRuns={getNetworkManagerReports}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {[HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET, HANDLE_GROUP_SLIDEOUT.EDIT_BUCKET].includes(
                action
            ) && (
                <CreateOrEditGroup
                    bucket={bucket}
                    action={action}
                    reportRuns={allReports}
                    show={[
                        HANDLE_GROUP_SLIDEOUT.ADD_NEW_BUCKET,
                        HANDLE_GROUP_SLIDEOUT.EDIT_BUCKET
                    ].includes(action)}
                    onSidebarClose={onSidebarClose}
                    getReportRuns={getNetworkManagerReports}
                />
            )}
            {action === 'editReportDetails' && (
                <EditReportDetailsSlideout
                    reportRun={reportRun}
                    onClose={onSidebarClose}
                    buckets={buckets}
                    reportRunsInfo={allReports}
                />
            )}
            {action === 'deleteReportDetails' && (
                <DeleteReportGroupModal
                    records={selectedRecords}
                    open={action === 'deleteReportDetails'}
                    title="Confirm Report Deletion(s)"
                    prefix={<b>Report Name:</b>}
                    fetchData={getNetworkManagerReports}
                    onAction={() => setAction(null)}
                    onClose={onSidebarClose}
                />
            )}
            {action === 'comparingDisabled' && (
                <ConfirmationDialog onAction={() => setAction(null)} />
            )}
            {isCompletedReport.show && newNotificationFlow && <CompletedModal />}
            {newNotificationFlow && <AlertsContainer alerts={alertsContext?.alerts} />}
        </div>
    );
};

export default NetworkManager;
