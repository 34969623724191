import React, { useRef, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

interface DatePickerWrapperProps {
    selectedValue: Date | null;
    setSelectedValue: (date: Date | null) => void;
    limit: Date;
    facetedMax: Date;
    facetedMin: Date;
    inputRenderingFn: any;
}
export default function DatePickerWrapper({
    selectedValue,
    setSelectedValue,
    limit,
    facetedMin,
    facetedMax,
    inputRenderingFn
}: DatePickerWrapperProps) {
    const theme = useTheme();
    const [isActive, setIsActive] = useState(false);
    const datePickerRef = useRef();

    const wrappedInputRenderingFn = () => {
        const inputElement = inputRenderingFn({ isActive, clickHandler: () => setIsActive(true) });
        return <Box ref={datePickerRef}>{inputElement}</Box>;
    };

    const paperStyle = {
        backgroundColor: theme.palette.neutral.neutral2,
        // color: theme.palette.common.white,
        '& .MuiButtonBase-root': {
            // color: theme.palette.common.white,
            '&.Mui-disabled': { color: theme.palette.neutral.neutral4 },
            cursor: 'pointer'
        },
        // '& .MuiTypography-root': { color: theme.palette.common.white },
        '& .MuiPickersDay-root': {
            backgroundColor: 'transparent',
            '&.Mui-selected': {
                backgroundColor: theme.palette.semantic.focusedBlue
            }
        },
        // '& .MuiCalendarPicker-root': { height: '300px' },
        '& .MuiClockPicker-root': {
            color: theme.palette.common.white,
            '& span': { color: 'inherit' }
        },
        marginTop: '6px'
    };

    const inputStyle = (isActive: boolean) => ({
        padding: '0 6px',
        '& .MuiSvgIcon-root': {
            color: isActive ? theme.palette.common.black : theme.palette.neutral.neutral6
        },
        color: isActive ? theme.palette.common.black : theme.palette.neutral.neutral6,
        '& .MuiInputAdornment-root': { marginRight: '6px' },
        '& .MuiInputBase-input': {
            color: isActive ? theme.palette.common.black : theme.palette.neutral.neutral6
        }
    });

    return (
        <DatePicker
            renderInput={wrappedInputRenderingFn}
            PopperProps={{
                id: 'filter',
                placement: 'bottom-end',
                anchorEl: datePickerRef.current
            }}
            PaperProps={{
                sx: paperStyle
            }}
            InputProps={{
                sx: inputStyle(isActive),
                value: selectedValue,
                disabled: true
            }}
            open={isActive}
            onClose={() => {
                setIsActive(false);
            }}
            onOpen={() => {
                setIsActive(true);
            }}
            onChange={(value: any) => {
                if (value instanceof Date) {
                    setSelectedValue(value);
                }
            }}
            minDate={facetedMin}
            maxDate={facetedMax}
            value={selectedValue ?? limit}
        />
    );
}
