/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import theme from 'theme';
import { Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatValue } from 'utils/number.util';
import FormattedKPIValue from './components/FormattedKPIValue';
import { RootState } from 'redux/reducers';
import { useFlags } from 'launchdarkly-react-client-sdk';

const KPICard = styled(Box)(() => ({
    width: '230px',
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '56px',
    paddingLeft: '32px',
    fontSize: 14
}));

const KPIBoard = styled(Box)(() => ({
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

const KPIDashboard = () => {
    const { compare_run }: any = useParams();
    const {
        v2KpIs,
        odpt4097ChangeNameToLoadedMilesRatioOnNetwork,
        odpt4173HideCoverageKpi,
        odpt4098HideOnTimeKpi,
        hideTotalRevenueKpi,
        hideProfitKpi,
        hideLoadsTakenAvailableKpi,
        hideLoadedMilesRatioKpi
    } = useFlags();

    const { kpi } = useSelector((state: RootState) => state.NetworkReducer);
    const { organization } = useSelector((state: RootState) => state.SignInReducer);
    const isOrgTypeShipper = organization?.org_type === 'shipper';

    const loadCountLabel = v2KpIs ? 'loads_accepted' : 'load_count';

    const dashboardMetrics = [
        {
            name: isOrgTypeShipper ? 'External Cost' : 'Total Revenue',
            hidden: hideTotalRevenueKpi,
            content: (
                <FormattedKPIValue
                    type={null}
                    isDeltaMode={compare_run}
                    reportValue={kpi?.[v2KpIs ? 'revenue' : 'total_revenue']}
                    prefix="$"
                />
            )
        },
        {
            name: isOrgTypeShipper ? 'Savings' : 'Profit',
            hidden: hideProfitKpi,
            content: (
                <FormattedKPIValue
                    type={null}
                    isDeltaMode={compare_run}
                    reportValue={kpi?.profit}
                    prefix="$"
                />
            )
        },
        {
            name: 'Loads Taken / Available',
            hidden: hideLoadsTakenAvailableKpi,
            content: (
                <FormattedKPIValue
                    type={null}
                    isDeltaMode={compare_run}
                    loadCount={kpi?.[loadCountLabel]?.value}
                    loadAvailable={kpi?.loads_available?.value}
                    reportValue={
                        kpi?.[loadCountLabel] && kpi?.loads_available
                            ? {
                                  value: `${formatValue(kpi?.[loadCountLabel]?.value)}${
                                      kpi?.[loadCountLabel]?.isCompared ? '%' : ''
                                  }
       /
        ${formatValue(kpi?.loads_available?.value)}${kpi?.loads_available?.isCompared ? '%' : ''}`,
                                  isCompared:
                                      kpi?.[loadCountLabel]?.isCompared &&
                                      kpi?.loads_available?.isCompared
                              }
                            : {}
                    }
                    format="0/0"
                />
            )
        },
        {
            name: odpt4097ChangeNameToLoadedMilesRatioOnNetwork
                ? 'Loaded Miles Ratio'
                : 'Loaded Miles',
            hidden: hideLoadedMilesRatioKpi,
            content: (
                <FormattedKPIValue
                    type={null}
                    isDeltaMode={compare_run}
                    reportValue={kpi?.[v2KpIs ? 'loaded_percent' : 'loaded_miles']}
                    isPercentage={true}
                    format="0.00"
                />
            )
        },
        {
            name: 'On Time',
            hidden: odpt4098HideOnTimeKpi,
            content: (
                <FormattedKPIValue
                    type={null}
                    isDeltaMode={compare_run}
                    reportValue={kpi?.[v2KpIs ? 'service_on_time' : 'on_time']}
                    isPercentage={true}
                    format="0.00"
                />
            )
        },
        {
            name: isOrgTypeShipper ? '% Covered By Fleet' : 'Service Coverage',
            hidden: odpt4173HideCoverageKpi,
            content: (
                <FormattedKPIValue
                    type="percentCover"
                    isDeltaMode={compare_run}
                    reportValue={kpi?.service_coverage}
                    isPercentage={true}
                    format="0.00"
                />
            )
        }
    ];

    return (
        <KPIBoard>
            {dashboardMetrics.map((item, i) => {
                if (Boolean(item?.name && !item?.hidden)) {
                    return (
                        <KPICard component="section" key={i}>
                            <Box component="span">{item.name}:</Box>
                            <Box component="span" sx={{ fontWeight: 600 }}>
                                {item.content}
                            </Box>
                        </KPICard>
                    );
                }
                return null;
            })}
        </KPIBoard>
    );
};

export default KPIDashboard;
